.contact-container {
  min-height: calc(100vh - 150px);
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid red; */
  width: 100%;
  padding-top: 170px;
}

.contact-container section {
  width: 80%;
  display: flex;
}

.founders-container {
  width: 50%;
  /* border: 2px solid red; */
  text-align: center;
  padding: 30px;
}

.form-container {
  width: 50%;
  /* border: 2px solid red; */
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.founders-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container h3 {
    text-align: center;
}

.founders-container div {
  display: flex;
  justify-content: center;
}

.contact-email p, .contact-email a {
  text-decoration: none;
}

.contact-socials {
  display: flex;
  justify-content: center;
  gap: 80px;
  width: 250px;
}

.contact-socials p {
  text-align: center;
}

.contact-socials a {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  color: #666666;
  text-decoration: none;
}

.contact-socials a:hover {
  color: black;
}

.contact-socials i {
  font-size: 3rem;
}

.founder-image-container {
  width: 70%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.founder-image-container img {
  width: 100%;
}

.request-input {
    display: flex;
    flex-direction: column;
}

.request-input input, .request-input textarea {
    padding: 2px;
    background-color: #fbfbfb;
    outline: none;
    border: 1px solid black;
    font-size: 1.3rem;
}

.request-input textarea {
    height: 150px;
}

.request-input label {
    font-size: 1rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 500px;

}

.name-container {
    display: flex;
    justify-content: space-between;
}

.name-container div {
    width: 45%;
}

.form-container button {
    align-self: flex-end;
    width: 100px;
    padding: 7px 10px;
    background-color: #252525;
    color: white;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    border: none;
}

.form-container button:hover {
    background-color: #343434
}

@media (max-width: 600px) {
    .founders-container {
        width: 100%;
        
      }

      .founder-image-container {
        width: 100%;
      }
      
      .form-container {
        width: 100%;
        
      }
      .contact-container section {
        flex-direction: column;
        width: 100%;
      }
      form {
        width: 100%;
      }
      .request-input label {
        font-size: .8rem;
    }
}

@media (max-width: 374px) {
  
    .request-input label {
      font-size: .6rem;
  }
}