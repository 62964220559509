footer {
    color: white;
    background-color: #191919;
    padding: 25px 5%;
    height: 600px;
    display: flex;
    justify-content: center;
    width: 100%;
}

footer section {
    display: flex;
    width: 100%;
    max-width: 2400px;
}

footer p {
    font-size: 1.3rem;
}

.map-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
    height: 100%;
}

.map-container {
    width: 100%;
    height: 100%;

}

.map-container iframe {
    width: 100%;
    height: 100%;

}

.map-info p {
    align-self: flex-end
}

.footer-links-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
}

.footer-links-container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.socials-container {
    display: flex;
    justify-content: center;
    gap: 80px;
    width: 250px;
}

.socials-container p {
    text-align: center;
}

.socials-container a {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: white;
    text-decoration: none;
}

.socials-container a:hover {
    color: lightgrey;
}

.socials-container i {
    font-size: 3rem;
}

@media (max-width: 1400px) {
    footer {
        height: 450px;
    }
}

@media (max-width: 600px) {

    footer {
        height: auto;
    }
    footer section {
        flex-direction: column;
        gap: 50px;
    }
    footer p {
        font-size: 1rem;
    }
    .map-info {
        width: 100%;
        height: 400px;
    }
    .footer-links-container {
        width: 100%;
       gap: 40px;
    }
}