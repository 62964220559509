* {
  box-sizing: border-box;
  margin: 0;
  /* border: 2px solid red; */
  font-family:'Gordita', sans-serif;
}

.App {
  background-color: black;
  min-height: 100vh;
  color: white;
  font-size: 1.5rem;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  p {
    font-size: 1rem;
  }
  li {
    font-size: 1.3rem;
  }
  label {
    font-size: .8rem;
  }
}