header {
  background-color: black;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.nav-container {
    /* border: 2px solid white; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: fixed; */
    width: 95%;
    background-color: black;
    padding-top: 10px;
    max-width: 2000px;
}

.logo-container {
    height: 130px;
    width: 300px;
}

.logo-container img {
    height: 100%;
    width: 100%;
}

.nav-list {
    display: flex;
    gap: 50px;
    list-style: none;
    text-decoration: none;
    padding-right: 50px;
}

.nav-link {
    text-decoration: none;
}

.hamburger {
    display: hidden;
  }
  
  .bar {
    display: hidden;
  }
  
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .nav-list.active {
    right: 0;
    top: 0;
  }

  @media (max-width: 800px) {

    .nav-list {
        position: absolute;
        /* z-index: 99999; */
        right: -100%;
        background-color: #fbfbfb;
        
        flex-direction: column;
        width: 200px;
        border-radius: 0 0 0 5px;
        gap: 15px;
        padding: 28px 10px;
        /* height: 100vh; */
        justify-content: flex-start;
        transition: .3s ease;
        z-index: 11;
      }

    .hamburger {
      display: block;
      padding: 1.5rem;
      position: fixed;
      top: 0;
      right: 0;
      margin: 3px 3px 0 0;
      color: white;
      z-index: 99;
      /* border: 2px solid white; */
    }
    .bar {
      display: block;
    
      width: 25px;
      height: 3px;
      margin: 5px auto;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      background-color: white;
    }
    .bar-active {
        background-color: black;
    }
  }