.home-container {
    
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.home-container section {
    width: 100%;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.carousel-container {
    height: calc(85dvh - 150px);
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
}
.carousel {
    width: 58%;
    height: 100%;
    max-width: 1600px;
    /* border: 2px solid red; */
}

.carousel div {
    height: calc(85dvh - 150px);
    width: 100%;
    /* border: 2px solid red; */
    border-radius: 5px;
}

.carousel img {
    height: 100%;
    width: 100%;
    /* border: 2px solid red; */
    object-fit: cover;
    object-position: center;
    /* border-radius: 5px; */
}

.carousel-zero img {
    object-position: bottom;
}

.carousel-one img {
    object-position: top;
}

.carousel p {
    width: 200px;
    color: red;
}

.home-title {
    text-align: center;
}

.home-title h1 {
    font-style: italic;
}

@media (max-width: 600px) {
    .carousel {
        width: 100%;
        height: 100%;
        /* border: 2px solid red; */
    }
    .carousel div {
        border-radius: 0px;
    }
}