.menu-container {
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 150px;
    background-color: black;
}

.menu-container section {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
}

.menu-container div {
    width: 50%;
    padding: 0 5%;
    /* border: .5px solid white; */
}

.menu-container img {
    width: 100%;
}

@media (max-width: 600px) {
    .menu-container div {
        width: 100%;
        padding: 0 2%;
    }
}